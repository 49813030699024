body {
    margin: 0;
    font-family: "Raleway", sans-serif !important;
    color: #444444;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#header {
    /*background: #e9e8e6;*/
    box-shadow: 0px 0px 25px 0 rgba(0, 0, 0, 0.08);
}

#footer {
    box-shadow: 0px 0px 25px 0 rgba(0, 0, 0, 0.08);
}

#header .logo {
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
}

#header .logo a {
    text-decoration: none;
}

#header .logo img {
    max-height: 40px;
}
