.border-bottom {
  content: "";
  width: 50px;
  height: 3px;
  background: #34b7a7;
  bottom: 0;
  left: calc(50% - 25px);
  position: relative;
}

.resume-title {
  font-size: 26px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #222222;
}

.resume-item {
  padding: 0 0 20px 20px;
  margin-top: -2px;
  border-left: 2px solid #34b7a7;
  position: relative;
  font-size: 16px;
  font-family: "Open Sans", sans-serif !important;
  color: #444444;
}

.resume-item ul{
  padding-left: 20px;
}

.resume-item ul li{
  padding-bottom: 10px;
}

.resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #34b7a7;
}

.resume-item h4 {
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #1d685f;
  margin-bottom: 10px;
}

.resume-item h5 {
  font-size: 16px;
  background: #effbf9;
  padding: 5px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
  color: #34b7a7;
}

.filteredContent {
  opacity: 1;
  transition: opacity 0.4s linear; /* Adjust the transition properties */
}

.filteredContent.hidden {
  opacity: 0;
  transition: opacity 0.4s ease-in-out; /* Adjust the transition properties */
}
